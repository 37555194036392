// Importiere die createApp Funktion aus Vue
import { createApp } from 'vue';

// Erstelle die Vue-Anwendung
const app = createApp({
    data() {
        return {
            message: 'Hallo, Vue 3!'
        };
    },
    methods: {
        changeMessage() {
            this.message = 'Nachricht wurde geändert!';
        }
    }
});

// Montiere die Anwendung auf das DOM-Element mit der id "app"
app.mount('#app');
